import {useState} from "react";
import {onAuthStateChanged, getAuth, signOut, signInWithPopup, GoogleAuthProvider} from 'firebase/auth';
import EditForm from "./EditForm";


const Editor = props => {

    const [user, setUser] = useState(null);
    onAuthStateChanged(getAuth(), user => {
        setUser(user||null);
    })

    const useGoogle = () => {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        signInWithPopup(auth, provider)
            .then((result) => {
                // Signed in
            }).catch((error) => {
            const errorMessage = error.message;
            alert(errorMessage);
        });
    }

    if (user && !user.email.toLowerCase().endsWith("melonverse.com")){
        alert("You must use a melonverse.com email");
        signOut();
    }

    return (
        <div className={'w-full mx-auto'}>
            {!user?(
                <div className={'w-full min-h-screen flex flex-wrap justify-center items-center'}>
                    <div className={'text-center'}>
                        <h1 className={'text-5xl'}>🗝️</h1>
                        <h1 className={'text-3xl mt-6'}>Staff Area</h1>
                        <button onClick={useGoogle} className={'mt-6 bg-blue-700 hover:bg-blue-600 rounded-lg px-4 py-2 text-white font-bold text-2xl'}>
                            Sign in with Google
                        </button>
                    </div>
                </div>
            ):(
                <div className={'flex flex-row items-center'}>
                    <div className={'w-3/4 px-6 py-3'}>
                        <h1 className={'text-3xl'}>Edit Contact Card: {user.email}</h1>
                        <EditForm user={user}/>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Editor;