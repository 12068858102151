import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getFirestore, collection, where, limit, query, getDocs} from 'firebase/firestore'
import {ref, getStorage, getDownloadURL} from "firebase/storage";
import '../../fonts.css'
import logo from '../../img/melon logo img.svg.svg';
import '../../colorfilter.css';
import VCard from 'vcard-creator'
import moment from "moment";


const Loader = props => {

    const params = useParams();
    const [profile, setProfile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [notFound, setNotFound] = useState(false);

    const createContactCard = () => {

        let myVCard = new VCard()

        myVCard.addName(profile.lName, profile.fName)
            .addCompany("Melon, Inc.")
            .addEmail(profile.email)
            .addURL("https://melon.directory/" + profile.url)
            .addNote("Added to contacts: " + moment().format('MMMM Do, YYYY'))
        ;
        if (profile.title){
            myVCard = myVCard.addJobtitle(profile.title);
        }
        if (profile.phoneNumber){
            myVCard = myVCard.addPhoneNumber(profile.phoneNumber);
        }
        if (imageUrl){
            myVCard = myVCard.addPhotoURL(imageUrl);
        }

        const textData = myVCard.toString();
        const data = new Blob([textData], {
            type: 'text/x-vcard'
        })
        return window.URL.createObjectURL(data);

    };

    const findProfile = async url => {
        const results = await getDocs(query(collection(getFirestore(), "profiles"), where("url", "==", url.toLowerCase()), limit(1)));
        if (results.size > 0) {
            setProfile(results.docs[0].data());

            const p = results.docs[0].data();

            if (p.photo && p.photo.fullPath) {
                const url = await getDownloadURL(ref(getStorage(), p.photo.fullPath));
                setImageUrl(url);
            }
        } else {
            setNotFound(true);
        }
    }

    useEffect(() => {
        if (params.url) {
            findProfile(params.url);
        }
    }, [params])

    if (!params.url) {
        window.location.href = 'https://melonverse.com';
        return;
    }

    if (notFound) {
        return (
            <div className={'w-full min-h-screen digital-turq text-center mx-auto w-full'}
                 style={{backgroundColor: "#0f0324", color: '#3b2bac2'}}>
                <i className={'far fa-frown text-5xl digital-turq py-24'}></i>
                <h2 className={'melon-headline text-5xl'}>Not found.</h2>
                <div className={'text-center'}>
                    <a href={'https://melonverse.com'} target={'_blank'}>
                        <img src={logo} alt={'MELON'} className={'w-36 inline text-center mt-12'}/>
                    </a>
                </div>
            </div>
        );
    }

    if (!profile) {
        return null;
    }

    document.title = (profile.fName && profile.lName) ? profile.fName + " " + profile.lName : 'MELON Biz Card';

    const vcardUrl = createContactCard();

    return (
        <div className={' w-full min-h-screen'} style={{backgroundColor: "#0f0324", color: '#3b2bac2'}}>
            <div className={'w-full transition-all mx-auto md:max-w-sm'}>
                <div className={'animate__animated animate__bounceInUp'}>
                    {imageUrl ? (
                        <div className="w-full" style={{filter: 'grayscale(1)'}}>
                            <img src={imageUrl} className={'animate__animated animate__fadeInDown animate__delay-1s'}
                                 alt="image"/>
                        </div>
                    ) : null}
                    {profile.fName || profile.lName ? (
                        <h1 className={'text-5xl digital-turq melon-headline text-white uppercase m-4'}>{profile.fName + " " + profile.lName}</h1>
                    ) : null}
                    {profile.title ? (
                        <h1 className={'text-3xl tech-silver melon-detail not-italic text-white uppercase m-4'}>{profile.title}</h1>
                    ) : null}
                    <div className={'border-t py-6 mt-4 border-white w-full'}>

                    </div>
                    <div className={'px-4'}>
                        <a className={'w-full block digital-turq text-xl my-2 px-4 py-2 melon-headline border border-digital-turq'}
                           href={vcardUrl} target={'_blank'}>
                            <i className={'fas fa-user-plus'}></i> Add to Contacts
                        </a>
                        <a className={'w-full block digital-turq text-xl my-2 px-4 py-2 melon-headline border border-digital-turq'}
                           href={'mailto:' + profile.email}>
                            <i className={'fas fa-envelope'}></i> {profile.email}
                        </a>
                        {profile.phoneNumber ? (
                            <a className={'w-full block digital-turq text-xl my-2 px-4 py-2 melon-headline border border-digital-turq'}
                               href={'tel:' + profile.phoneNumber}>
                                <i className={'fas fa-phone'}></i> {profile.phoneNumber}
                            </a>
                        ) : null}
                        {profile.linkedIn ? (
                            <a className={'w-full block digital-turq text-xl my-2 px-4 py-2 melon-headline border border-digital-turq'}
                               target={'_blank'}
                               href={(!profile.linkedIn.startsWith("http") ? 'https://' : '') + profile.linkedIn}>
                                <i className={'fab fa-linkedin'}></i> Connect on LinkedIn
                            </a>
                        ) : null}
                        {profile.twitter ? (
                            <a className={'w-full block digital-turq text-xl my-2 px-4 py-2 melon-headline border border-digital-turq'}
                               target={'_blank'}
                               href={'https://twitter.com/' + profile.twitter}>
                                <i className={'fab fa-twitter'}></i> @{profile.twitter}
                            </a>
                        ) : null}
                    </div>

                </div>
                <div className={'animate__animated animate__fadeIn animate__delay-1s text-center'}>
                    <a href={'https://melonverse.com'} target={'_blank'}>
                        <img src={logo} alt={'MELON'} className={'w-36 inline text-center mt-12'}/>
                    </a>
                    <p className={'text-sm melon-detail mt-4 tech-silver'}>&copy; 2022 Melon, Inc.</p>
                </div>
            </div>
        </div>
    );

}
export default Loader;