import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyC7_vMGfT_xD0snJ40N3i9F1MdQDvbo7JQ",
    authDomain: "melon-biz-cards.firebaseapp.com",
    projectId: "melon-biz-cards",
    storageBucket: "melon-biz-cards.appspot.com",
    messagingSenderId: "30311837424",
    appId: "1:30311837424:web:4ce2003680255b91de1c35",
    measurementId: "G-DJJWD84T5T"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
