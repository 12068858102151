import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Editor from "./Containers/Editor/Editor";
import Loader from "./Containers/BizCards/Loader";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/edit'} element={<Editor/>}/>
          <Route path={'/:url*'} element={<Loader/>}/>
          <Route path={'*'} element={<Loader/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
