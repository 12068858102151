import {useEffect, useState} from "react";
import * as countryList from 'country-list'
import {getFirestore, setDoc, getDoc, collection, doc} from 'firebase/firestore';
import {getStorage, uploadBytes, getDownloadURL, ref} from 'firebase/storage';
import {upload} from "@testing-library/user-event/dist/upload";


const EditForm = props => {

    const [fName, setFName] = useState("");
    const [lName, setLName] = useState("");
    const [bio, setBio] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [title, setTitle] = useState("");
    const [country, setCountry] = useState("United States of America")
    const [twitter, setTwitter] = useState("")
    const [imageUrl, setImaheUrl] = useState(null);

    const savePhoto = async file => {

        const r = ref(getStorage(), "photos/" + props.user.uid + "/" + file.name);
        const result = await uploadBytes(r, file);
        await setDoc(doc(getFirestore(), "profiles/" + props.user.uid), {
            photo: {
                name: result.ref.name,
                fullPath: result.ref.fullPath,
                bucket: result.ref.bucket
            }
        }, {
            merge: true
        })
        alert("Uploaded!")

    };

    const saveDoc = async () => {
        await setDoc(doc(getFirestore(), "profiles/" + props.user.uid), {
            fName,
            lName,
            bio,
            phoneNumber,
            linkedIn,
            title,
            country,
            twitter,
            email: props.user.email,
            url: fName.toLowerCase() + "-" + lName.toLowerCase()
        }, {
            merge: true
        })
        alert("Updated!")
    }

    useEffect(() => {

        getDoc(doc(getFirestore(), "profiles/" + props.user.uid)).then(doc => {
            if (doc.exists()) {
                const d = doc.data();
                setFName(d.fName || "");
                setLName(d.lName || "");
                setBio(d.bio || "");
                setPhoneNumber(d.phoneNumber || "");
                setLinkedIn(d.linkedIn || "");
                setTitle(d.title || "");
                setCountry(d.country || "");
                setTwitter(d.twitter || "")
                if (d.photo && d.photo.fullPath){
                    console.log(d.photo)
                    const r = ref(getStorage(), d.photo.fullPath);
                    getDownloadURL(r).then(url => {
                        setImaheUrl(url);
                    })
                }
            }
        })

    }, [props.user])

    return (
        <>
            <div>
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <div className="px-4 sm:px-0">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Contact Card</h3>
                            <p className="mt-1 text-sm text-gray-600">
                                Info you choose to share when visitors come to your profile
                            </p>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form onSubmit={e => {
                            e.preventDefault();
                            saveDoc();
                        }}>
                            <div className="shadow sm:rounded-md sm:overflow-hidden">
                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                    <div className="grid grid-cols-3 gap-6">
                                        <div className="col-span-3 sm:col-span-2">
                                            <label htmlFor="company-website"
                                                   className="block text-sm font-medium text-gray-700">
                                                Card Link
                                            </label>
                                            <div className="mt-1 flex rounded-md shadow-sm">
                        <span
                            className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                          https://melon.directory/
                        </span>
                                                <input
                                                    type="text"
                                                    value={fName && lName ? fName.toLowerCase() + '-' + lName.toLowerCase() : ''}
                                                    disabled
                                                    name="company-website"
                                                    id="company-website"
                                                    className="opacity-50 cursor-not-allowed focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300"
                                                    placeholder="Fill out your full name to create your URL"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                            Short Bio
                                        </label>
                                        <div className="mt-1">
                      <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder=""
                          maxLength={280}
                          value={bio}
                          onChange={e => {
                              setBio(e.target.value);
                          }}
                      />
                                        </div>
                                        <p className="mt-2 text-sm text-gray-500">
                                            Brief description of you
                                        </p>
                                    </div>

                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">Photo</label>
                                        <div className="mt-1 flex items-center">
                      <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                        {imageUrl?(
                            <img src={imageUrl} className={'w-full h-full inline rounded-full'}/>
                        ):(
                            <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                <path
                                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"/>
                            </svg>
                        )}
                      </span>
                                            <label htmlFor='photoChange'>
                                                <input onChange={e => {
                                                    const files = e.target.files;
                                                    console.log(e);
                                                    if (files.length > 0){
                                                        savePhoto(files[0])
                                                    }
                                                }} type={'file'} className={'px-4'} accept={'.png,.jpg,.jpeg'} id={'photoChange'}/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="hidden sm:block" aria-hidden="true">
                <div className="py-5">
                    <div className="border-t border-gray-200"/>
                </div>
            </div>

            <div className="mt-10 sm:mt-0">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">

                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form onSubmit={e => {
                            e.preventDefault();
                            saveDoc();
                        }}>
                            <div className="shadow overflow-hidden sm:rounded-md">
                                <div className="px-4 py-5 bg-white sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium text-gray-700">
                                                First name
                                            </label>
                                            <input
                                                type="text"
                                                name="first-name"
                                                id="first-name"
                                                value={fName}
                                                onChange={e => {
                                                    setFName(e.target.value)
                                                }}
                                                autoComplete="given-name"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="last-name"
                                                   className="block text-sm font-medium text-gray-700">
                                                Last name
                                            </label>
                                            <input
                                                type="text"
                                                name="last-name"
                                                id="last-name"
                                                value={lName}
                                                onChange={e => {
                                                    setLName(e.target.value)
                                                }}
                                                autoComplete="family-name"
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-4">
                                            <label htmlFor="email-address"
                                                   className="block text-sm font-medium text-gray-700">
                                                Email address
                                            </label>
                                            <input
                                                disabled
                                                type="text"
                                                value={props.user.email}
                                                name="email-address"
                                                id="email-address"
                                                autoComplete="email"
                                                className="opacity-50 cursor-not-allowed mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label htmlFor="country"
                                                   className="block text-sm font-medium text-gray-700">
                                                Country
                                            </label>
                                            <select
                                                value={country}
                                                onChange={e => {
                                                    setCountry(e.target.value)
                                                }}
                                                id="country"
                                                name="country"
                                                autoComplete="country-name"
                                                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            >
                                                {countryList.getNames().map(c => {
                                                    return <option key={c}>{c}</option>
                                                })}
                                            </select>
                                        </div>

                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Job title (Match your title on Basecamp)
                                            </label>
                                            <input
                                                type="text"
                                                value={title}
                                                onChange={e => {
                                                    setTitle(e.target.value)
                                                }}
                                                placeholder={'Game Developer'}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Phone number (optional)
                                            </label>
                                            <input
                                                type="tel"
                                                value={phoneNumber}
                                                onChange={e => {
                                                    setPhoneNumber(e.target.value)
                                                }}
                                                placeholder={'555...'}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                Twitter Username (optional, just your username)
                                            </label>
                                            <input
                                                type="text"
                                                value={twitter}
                                                onChange={e => {
                                                    setTwitter(e.target.value)
                                                }}
                                                placeholder={'Melonverse'}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                        <div className="col-span-6">
                                            <label className="block text-sm font-medium text-gray-700">
                                                LinkedIn Profile (optional)
                                            </label>
                                            <input
                                                type="url"
                                                value={linkedIn}
                                                onChange={e => {
                                                    setLinkedIn(e.target.value)
                                                }}
                                                placeholder={'linkedin.com...'}
                                                className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default EditForm;
